import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import SelectSearch from "react-select-search";
// import "react-select-search/style.css";
import hoursArray from "../config/hoursArray.json";
import { timeSlots } from "../store/timeSlotStore";
import ToggleSwitch from "./ToggleSwitch";

function Slot({ title, clinicType, id, data, disabled, hourList }) {
  const timeSlot = timeSlots((state) => state.timeSlot);
  const setTimeSlot = timeSlots((state) => state.setTimeSlot);
  const [optionsFrom, setOptionsFrom] = useState(hourList);
  const [optionsTo, setOptionsTo] = useState(hourList);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");

  useEffect(() => {
    if (data) {
      setFromTime(data.from_time);
      setToTime(data.to_time);
    }
  }, [data]);

  useEffect(() => {
    if (fromTime && toTime) {
      setTimeSlot({
        ...timeSlot,
        [title]: {
          ...timeSlot[title],
          [clinicType]: {
            ...timeSlot[title][clinicType],
            [id]: { from_time: fromTime, to_time: toTime },
          },
        },
      });
    }
  }, [fromTime, toTime]);

  // useEffect(() => {
  //   if (!_.isEmpty(fromTime)) {
  //     let selectedHourIndex = options.findIndex(
  //       (item) => item.value == fromTime
  //     );
  //     setOptions((prev) => {
  //       let temp = prev;
  //       return temp.slice(selectedHourIndex + 1);
  //     });
  //   }
  // }, [fromTime]);

  return (
    <>
      <style>
        {`

.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Inter', sans-serif;

  width: 300px;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
      --select-search-font: 'Inter var', sans-serif;
  }
}

@media (prefers-color-scheme: dark) {
  .select-search-container {
      --select-search-background: #000;
      --select-search-border: #313244;
      --select-search-selected: #89b4fa;
      --select-search-text: #fff;
      --select-search-subtle-text: #a6adc8;
      --select-search-highlight: #1e1e2e;
  }
}

body.is-dark-mode .select-search-container {
  --select-search-background: #000;
  --select-search-border: #313244;
  --select-search-selected: #89b4fa;
  --select-search-text: #fff;
  --select-search-subtle-text: #a6adc8;
  --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  border-radius: 3px;
  outline: none;
  font-family: var(--select-search-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-select {
  background: var(--select-search-background);
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-search-border);
  overflow: auto;
  max-height: 120px !important;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-radius: 3px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-search-background);
  border: none;
  outline: none;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--select-search-border);
  color: var(--select-search-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}

          `}
      </style>
      <div className="grid grid-cols-2 gap-4 items-center z-0">
        <div className="grid gap-1 my-5">
          <label className="text-gray-500 text-xs font-medium">From</label>
          <SelectSearch
            options={optionsFrom}
            value={fromTime}
            name="from"
            placeholder="-- : -- AM"
            search={false}
            emptyMessage={
              <p className="text-sm py-3">no available slots to select</p>
            }
            onChange={(e) => {
              setToTime("");
              setFromTime(e);
              let selectedHourIndex = optionsFrom.findIndex(
                (item) => item.value == e
              );
              setOptionsTo(() => {
                let temp = optionsFrom;
                return temp.slice(selectedHourIndex + 1);
              });
            }}
            disabled={disabled}
          />
        </div>
        <div className="grid gap-1 my-5">
          <label className="text-gray-500 text-xs font-medium">To</label>
          <SelectSearch
            options={optionsTo}
            value={toTime}
            name="to"
            placeholder="-- : -- PM"
            search={false}
            emptyMessage={<p className="text-sm py-3">no result found</p>}
            onChange={(e) => {
              setToTime(e);
            }}
            disabled={disabled || _.isEmpty(fromTime)}
          />
        </div>
      </div>
    </>
  );
}

function TimeSlot({
  title,
  clinicType,
  bgColor,
  customToggleStyle,
  data,
  is_open,
  setCopyToPopup,
}) {
  const [isOpen, setIsOpen] = useState(is_open);
  const [slots, setSlots] = useState([0]);
  const timeSlot = timeSlots((state) => state.timeSlot);
  const setTimeSlot = timeSlots((state) => state.setTimeSlot);
  const [lastSelectedTime, setLastSelectedTime] = useState("");
  const [hourList, setHourList] = useState(hoursArray);
  useEffect(() => {
    let slot = Object.keys(data);
    if (slot.length !== 0) {
      setSlots(slot);
    }
  }, [data]);

  useEffect(() => {
    let temp = timeSlot[title][clinicType];
    if (!_.isEmpty(temp)) {
      let slotKeys = Object.keys(temp);
      let lastSlotIndex = slotKeys[slotKeys.length - 1];

      let selectedHourIndex = hoursArray.findIndex(
        (item) => item.value == temp[lastSlotIndex].to_time
      );
      setTimeout(() => {
        setHourList(() => {
          let temp = hoursArray;
          return temp.slice(selectedHourIndex);
        });
      }, 100);
    }
  }, [slots]);

  const deleteSlot = (slot) => {
    setSlots((prev) => {
      let temp = prev;
      temp.splice(temp.indexOf(slot) != -1 ? temp.indexOf(slot) : 999, 1);
      return [...temp];
    });
    let temp = timeSlot[title][clinicType];
    delete temp[slot];

    setTimeSlot({
      ...timeSlot,
      [title]: {
        ...timeSlot[title],
        [clinicType]: temp,
      },
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeSlot({
        ...timeSlot,
        [title]: { ...timeSlot[title], [clinicType]: [] },
      });
      setSlots([0]);
      setHourList(hoursArray);
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      !isEmpty(timeSlot?.Sunday?.in_clinic) ||
      !isEmpty(timeSlot?.Sunday?.virtual)
    ) {
      setIsOpen(true);
    }
  }, [timeSlot?.Sunday?.in_clinic, timeSlot?.Sunday?.virtual]);

  console.log(timeSlot, "time slot");

  return (
    <div className="">
      <div
        className={`grid grid-cols-[1fr,auto] gap-2 items-center ${
          title == "Monday" ? "mt-0" : "mt-20"
        }`}
      >
        <p
          className={classNames(
            "text-sm font-semibold transition-all duration-400",
            {
              "text-gray-500": isOpen || !isEmpty(data),
              "text-gray-400": !isOpen,
            }
          )}
        >
          {title}
        </p>

        <div className="inline-block justify-self-end">
          <ToggleSwitch
            isToggled={isOpen || !isEmpty(data)}
            setIsToggled={(e) => setIsOpen(e)}
            customStyle={customToggleStyle}
          />
        </div>
      </div>
      <AnimatePresence>
        {(isOpen || !isEmpty(data)) && slots
          ? slots.map((slot, index) => {
              return (
                <motion.div
                  initial={{ opacity: 1, y: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -15 }}
                  key={slot}
                >
                  <Slot
                    id={slot}
                    title={title}
                    clinicType={clinicType}
                    data={data[slot]}
                    disabled={slots.length - 1 !== index}
                    setLastSelectedTime={setLastSelectedTime}
                    lastSelectedTime={lastSelectedTime}
                    hourList={hourList}
                  />
                  {slot > 0 && slots.length - 1 === index ? (
                    <p
                      onClick={() => deleteSlot(slot)}
                      className="text-red-600 font-medium text-sm px-2 inline-flex gap-2 items-center"
                    >
                      <BsPlusLg size="18px" className="rotate-45" />
                      Delete slot
                    </p>
                  ) : (
                    ""
                  )}
                </motion.div>
              );
            })
          : ""}
      </AnimatePresence>

      {isOpen || !isEmpty(data) ? (
        <div className="mt-3 inline-grid gap-4 grid-cols-2 items-center w-full">
          <div
            onClick={() => {
              setSlots([...slots, +slots[slots.length - 1] + 1]);
            }}
          >
            <button
              type="button"
              className="bg-blue-500 text-white rounded-md p-1.5"
            >
              <p className="text-sm px-1 flex gap-1 items-center">
                <BsPlusLg size="16px" /> Add other slot
              </p>
            </button>
          </div>
          <p
            className="ml-auto text-blue-500"
            onClick={() => setCopyToPopup({ title, clinicType })}
          >
            copy to
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TimeSlot;
