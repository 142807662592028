import GoogleMapReact from "google-map-react";
import { useState, useEffect } from "react";
import { FaMapPin } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { onBoardStoreData } from "../store/onboardStore";

const geoLocationKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export default function SimpleMap({
  geoLocation,
  setShowMap,
  setGeoLocation,
  setLocationData = () => void 0,
  setValue,
  onCloseMap,
}) {
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const { lat, lng } = geoLocation;
  const defaultProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 11,
  };
  const [mapCords, setmapCords] = useState(defaultProps.center);
  const [updatedAddress, setUpdatedAddress] = useState({ lat: lat, lng: lng });
  const [placeApiPopup, setPlaceApiPopup] = useState(false);
  const handleDragEnd = (event) => {
    const newLat = event.lat;
    const newLng = event.lng;

    // Update map coordinates
    setmapCords({
      lat: newLat,
      lng: newLng,
    });

    // Update geolocation state
    setGeoLocation({
      lat: event.center.lat(),
      lng: event.center.lng(),
    });
    // setDoctorData((prevDoctorData) => ({
    //   ...prevDoctorData,
    //   lat: event.center.lat(),
    //   long: event.center.lng(),
    // }));
    // Update the updatedAddress state with new coordinates
    setUpdatedAddress({ lat: newLat, lng: newLng });
  };
  const handleUpdateAddress = async () => {
    // Check if google object is available
    if (!window.google) {
      console.error("Google Maps API not loaded");
      return;
    }
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: geoLocation.lat,
      lng: geoLocation.lng,
    };
    // Update latitude and longitude values
    setValue("latitude", String(geoLocation.lat));
    setValue("longitude", String(geoLocation.lng));
    setShowMap(false);
    try {
      const results = await geocoder.geocode({ location: latlng });
      if (results && results.results.length > 0) {
        setLocationData(results.results[0].formatted_address);
        setValue("address", results.results[0].formatted_address, {
          shouldValidate: true,
        });
        setUpdatedAddress({
          lat: geoLocation.lat,
          lng: geoLocation.lng,
          formattedAddress: results.results[0].formatted_address,
        });
      } else {
        window.alert("No results found");
      }
    } catch (error) {
      window.alert("Geocoder failed due to: " + error.message);
    }
  };
  const CenterPin = () => (
    <div
      style={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "35%",
        zIndex: 1,
      }}
    >
      <FaMapPin size={32} color={"red"} />
    </div>
  );
  //i want to show the updated address of the map in the div below
  return (
    <>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        className="fixed top-0 left-0 w-screen min-h-screen z-50 bg-white p-4 "
      >
        <div className="relative h-screen min-h-[-webkit-fill-available] max-w-[600px] my-0 mx-auto overflow-scroll pb-10">
          <div className="flex gap-3 items-center sticky top-0 bg-white z-10">
            <MdClose
              size="30px"
              className="text-medisage"
              onClick={() => {
                onCloseMap(); // Call the onCloseMap function here
                setPlaceApiPopup(false);
              }}
            />
            <p className="text-base text-gray-900 font-semibold">
              {/* Search your clinic */}
            </p>
          </div>
          <CenterPin />
          <div style={{ height: "70vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: geoLocationKey,
                libraries: ["places"],
              }}
              defaultCenter={{ lat: lat, lng: lng }}
              center={defaultProps.center}
              defaultZoom={30}
              zoom={18}
              options={{
                disableDefaultUI: false,
                fullscreenControl: false,
                mapTypeControl: false,
              }}
              onDragEnd={handleDragEnd}
            >
              {/* <AnyReactComponent lat={lat} lng={lng} text={"shubham"} /> */}
            </GoogleMapReact>
            <button
              className="mt-1 bg-blue-500 w-full h-9 rounded-md font-semibold text-white"
              onClick={handleUpdateAddress}
            >
              Mark Your Location
            </button>
            <div className="border-2 border-gray-500 rounded-md mt-3 border-gray-900 pointer-events-none">
              <Address
                lat={geoLocation.lat}
                lng={geoLocation.lng}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
// Make a component in react that accepts two props a lat and lng and return a div with human readable address.
function Address({ lat, lng, setValue }) {
  const [address, setAddress] = useState("");

  useEffect(() => {
    const fetchAddress = async () => {
      // Check if google object is available
      if (!window.google) {
        console.error("Google Maps API not loaded");
        return;
      }

      // Wait for the Geocoder constructor to be available
      if (!window.google.maps || !window.google.maps.Geocoder) {
        console.error("Google Maps Geocoder is not available");
        return;
      }

      const geocoder = new window.google.maps.Geocoder(); // Ensure to use 'new' here
      const latlng = {
        lat: lat,
        lng: lng,
      };

      console.log("Fetching address for coordinates:", latlng); // Log coordinates

      try {
        const results = await geocoder.geocode({ location: latlng });
        console.log("Geocoding results:", results.results[0]); // Log results

        if (results && results.results.length > 0) {
          setAddress(results.results[0].formatted_address); // Set the retrieved address
        } else {
          console.warn("No results found for the given coordinates.");
          window.alert("No results found");
        }
      } catch (error) {
        console.error("Geocoder failed due to:", error);
        window.alert("Geocoder failed due to: " + error.message);
      }
    };
    fetchAddress(); // Call the async function
  }, [lat, lng]);
  return (
    <textarea
      className="w-full h-full resize-none focus-within:outline-none focus-visible:outline-none p-2 rounded-md"
      value={address} // Use 'value' instead of 'defaultValue'
      onChange={(e) => {
        setAddress(e.target.value);
      }}
    />
  );
}
