export const login = "/login";

export const home = "/home";

export const surveyQuestion = "/survey-question";

export const googleForm = "/google-form";

export const landing = "/landing";

export const landingSurvey = "/landing-survey";

export const dashboard = "/dashboard";

export const contentType = "/content-type";

export const skillSet = "/skill-set";

export const campaigns = "/campaigns";

export const clinic = "/clinic";

export const eCardList = "/ecard-list";

export const generateECard = "/generate-ecard";

export const profile = "/profile";

export const editDocDetail = "/edit-doc-details";

export const DocList = "/doc-list";

export const chemistForm = "/chemist";

export const hqForm = "/hq-form";
