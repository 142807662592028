import { motion } from "framer-motion";
import { isEmpty, set } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { Controller, useForm } from "react-hook-form";
import { ImLocation2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "react-select-search/style.css";
import { toast } from "react-toastify";
import { addClinics, getMemberTemplate } from "../config/apiEndpoints";
import { getRequest, postRequest } from "../config/axiosInterceptor";
import { generateECard } from "../config/pathConstant";
import days from "../config/weekdays.json";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";
import Header from "./common/Header";
import Loader from "./common/Loader";
import SimpleMap from "./SimpleMap";
import Timing from "./Timing";
import { IoMdTime } from "react-icons/io";

const geoLocationKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const ChemistForm = () => {
  const timeSlot = timeSlots((state) => state.timeSlot);
  const [copyToPopup, setCopyToPopup] = useState(false);
  const [formattedSlots, setFormattedSlots] = useState([]);
  const selectedSpeciality = onBoardStoreData(
    (state) => state.selectedSpeciality
  );
  const setSelectedSpeciality = onBoardStoreData(
    (state) => state.setSelectedSpeciality
  );
  const specialitiesData = timeSlots((state) => state.specialitiesData);
  const setSpecialitiesData = timeSlots((state) => state.setSpecialitiesData);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const setDoctorTag = onBoardStoreData((state) => state.setDoctorTag);
  const setFormLang = onBoardStoreData((state) => state.setFormLang);
  const formLang = onBoardStoreData((state) => state.formLang);
  const [imagePreview, setImagePreview] = useState(null);
  const [geoLocation, setGeoLocation] = useState({ lat: 0, lng: 0 }); // Default geolocation state
  const [showMap, setShowMap] = useState(false);
  const [slot, setSlot] = useState([]);
  const [openTiming, setOpenTiming] = useState(false);
  const ecardId = timeSlots((state) => state.ecardId);
  const setSpecialityData = timeSlots((state) => state.setSpecialityData);
  const doctorData = onBoardStoreData((state) => state.doctorData);
  const doctorImg = onBoardStoreData((state) => state.doctorImg);
  const setDoctorImg = onBoardStoreData((state) => state.setDoctorImg);
  const campaignId = timeSlots((state) => state.campaignId);
  const [selectedLanguage, setSelectedLanguage] = useState(formLang);
  const [isOpen, setIsOpen] = useState(false);
  const langRef = useRef(null);

  const repSaveNo = localStorage.getItem("rep_mobile_number");

  const setRepDetails = onBoardStoreData((state) => state.setRepDetails);
  const repDetails = onBoardStoreData((state) => state.repDetails);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeoLocation({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
          console.log(position.coords.latitude, "latitude");
          console.log(position.coords.longitude, "longitude");
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (timeSlot) {
      let temp = [];
      Object.keys(timeSlot).map((day) => {
        let individualSlot = timeSlot[day];
        temp = [
          ...temp,
          {
            ...individualSlot,
            day: day,
            in_clinic: Object.values(individualSlot.in_clinic),
            virtual: Object.values(individualSlot.virtual),
          },
        ];
      });

      let formatted = temp.filter((slot) => {
        if (slot.in_clinic.length == 0 && slot.virtual.length == 0) {
          return false;
        } else {
          return true;
        }
      });
      if (formatted) setSlot(formatted);
    }
  }, [timeSlot]);

  const handleShowMap = () => {
    setShowMap(true);
  };

  useEffect(() => {
    let temp = [];
    Object.keys(timeSlot).map((day) => {
      let individualSlot = timeSlot[day];
      temp = [
        ...temp,
        {
          ...individualSlot,
          day: day,
          in_clinic: Object.values(individualSlot.in_clinic),
          virtual: Object.values(individualSlot.virtual),
        },
      ];
    });

    setFormattedSlots(
      temp.filter((slot) => {
        if (slot.in_clinic.length == 0 && slot.virtual.length == 0) {
          return false;
        } else {
          return true;
        }
      })
    );
  }, [timeSlot]);

  useEffect(() => {
    if (doctorData && selectedLanguage) {
      if (selectedLanguage === "english") {
        setSelectedSpeciality({
          label: doctorData?.speciality_name,
          value: doctorData?.speciality,
        });
      } else if (selectedLanguage === "hindi") {
        setSelectedSpeciality({
          label: doctorData?.speciality_hindi_name,
          value: doctorData?.speciality_hindi_name,
        });
      } else if (selectedLanguage === "marathi") {
        setSelectedSpeciality({
          label: doctorData?.speciality_marathi_name,
          value: doctorData?.speciality_marathi_name,
        });
      }
    }
  }, [doctorData, selectedLanguage]);

  useEffect(() => {
    if (!isEmpty(specialitiesData) && selectedSpeciality) {
      let foundItem;
      if (selectedLanguage === "hindi") {
        foundItem = specialitiesData.find((item) => {
          // console.log(selectedSpeciality, "---- &&&ENGLISH&&& ----", item);
          return item.hindi_name === selectedSpeciality.label;
        });
      } else if (selectedLanguage === "marathi") {
        foundItem = specialitiesData.find((item) => {
          // console.log(selectedSpeciality, "---- &&&HINDI&&& ----", item);
          return item.marathi_name === selectedSpeciality.label;
        });
      } else {
        foundItem = specialitiesData.find((item) => {
          // console.log(selectedSpeciality, "---- &&&MARATHI&&& ----", item);
          return item.name === selectedSpeciality.label;
        });
      }

      if (foundItem) {
        console.log(foundItem, "single item");
        setSpecialityData(foundItem); // Set the found item directly
      } else {
        setSpecialityData(null); // Handle case where no item is found
      }
    }
  }, [selectedSpeciality, selectedLanguage]);

  const cleanValue = (value) =>
    value === "null" || value === "undefined" ? null : value;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: doctorData?.first_name ?? "",
      lastName: doctorData?.last_name ?? "",
      speciality: doctorData?.speciality
        ? {
            label:
              selectedLanguage === "hindi"
                ? doctorData?.speciality_hindi_name
                : selectedLanguage === "marathi"
                ? doctorData?.speciality_marathi_name
                : doctorData?.speciality_name,

            value: doctorData?.speciality,
          }
        : null,
      clinicNumber: doctorData?.clinic_number ?? null,
      clinicName: doctorData?.clinic_name ?? null,
      address: doctorData?.address ?? null,
      lat: doctorData?.lat ?? null,
      long: doctorData?.long ?? null,
      degree: doctorData?.professional_degree ?? null,
      photo: doctorData?.profile_photo ?? null,
      email: doctorData?.email ?? null,
      mciNumber: doctorData?.doctor_mci ?? null,
      doctorNumber: doctorData?.doctor_number ?? null,
      whatsappNumber: doctorData?.whatsapp_number ?? null,
      repNumber: repSaveNo
        ? repSaveNo
        : doctorData?.payload
        ? doctorData?.payload[0]?.rep_mobile_number
        : null,
      designation: doctorData?.designation ?? null,
    },
  });

  useEffect(() => {
    setValue("firstName", doctorData?.first_name);
    setValue("lastName", doctorData?.last_name);
    setValue(
      "speciality",
      doctorData?.speciality
        ? {
            label:
              selectedLanguage === "hindi"
                ? doctorData?.speciality_hindi_name
                : selectedLanguage === "marathi"
                ? doctorData?.speciality_marathi_name
                : doctorData?.speciality_name,
            value: doctorData?.speciality,
          }
        : null
    );
    setValue("clinicNumber", doctorData?.clinic_number);
    setValue("clinicName", doctorData?.clinic_name);
    setValue("address", doctorData?.address);
    setValue("lat", doctorData?.lat);
    setValue("long", doctorData?.long);
    setValue("degree", doctorData?.professional_degree);
    setValue("photo", doctorData?.profile_photo ?? null);
    setValue("email", doctorData?.email ?? "");
    setValue("mciNumber", cleanValue(doctorData?.mci_number));
    setValue("doctorNumber", cleanValue(doctorData?.doctor_number));
    setValue("whatsappNumber", cleanValue(doctorData?.whatsapp_number));
    setValue(
      "repNumber",
      repSaveNo
        ? repSaveNo
        : doctorData?.payload && doctorData?.payload[0]?.rep_mobile_number
    );
    setValue("designation", doctorData?.designation);
    // setValue("brands", doctorData?.payload[0]?.doctor_brands ?? null);
    setSelectedSpeciality(
      doctorData?.speciality
        ? {
            label:
              selectedLanguage === "hindi"
                ? doctorData?.speciality_hindi_name
                : selectedLanguage === "marathi"
                ? doctorData?.speciality_marathi_name
                : doctorData?.speciality_name,
            value: doctorData?.speciality,
          }
        : null
    );
  }, [doctorData]);

  const handleCloseMap = () => {
    setShowMap(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("clinic_name", data?.clinicName ?? "");
    formData.append("email", data?.email ?? "");
    formData.append("clinic_number", data?.clinicNumber ?? "");
    formData.append("address", data?.address ?? "");
    formData.append("lat", geoLocation?.lat ?? "");
    formData.append("long", geoLocation?.lng ?? "");
    formData.append("timing", slot ? JSON.stringify(slot) : []);
    formData.append("doctor_number", data?.doctorNumber ?? "");
    formData.append("whatsapp_number", data?.whatsappNumber ?? "");
    formData.append("type", "chemist");
    formData.append("designation", data?.designation ?? "");
    formData.append("drug_license", data?.mciNumber ?? "");
    formData.append("shop_photo", doctorImg ? doctorImg : "");

    try {
      const request = await postRequest({
        url: addClinics,
        body: formData,
      });
      const response = request?.data;
      if (response?.status) {
        setDoctorData(response?.response);
        const id = response?.response?.id;
        localStorage.setItem(
          "rep_mobile_number",
          response?.response?.payload
            ? response?.response?.payload[0]?.rep_mobile_number
            : ""
        );
        if (!isEmpty(response?.response?.payload) && !isEmpty(repDetails)) {
          setRepDetails({
            ...repDetails, // Spread the existing properties of repDetails
            mobile_number: response.response.payload[0].rep_mobile_number, // Set mobile_number directly
          });
        }

        if (id) {
          sendMemberTemplate(id);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
      console.error("Error submitting form", error);
    }
  };

  const sendMemberTemplate = async (id) => {
    setLoading(true);
    try {
      const request = await postRequest({
        url: getMemberTemplate,
        body: {
          doctor_id: id,
          template_id: ecardId,
        },
      });
      const response = await request?.data;
      if (response?.status) {
        navigate(generateECard);
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDoctorImg(file);
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  useEffect(() => {
    if (doctorData?.profile_photo) {
      setImagePreview(doctorData?.profile_photo ?? null);
    }
  }, [doctorData]);

  useEffect(() => {
    const loadLocation = (latitude, longitude) => {
      console.log("Received latitude: " + latitude);
      console.log("Received longitude: " + longitude);
    };
    loadLocation();
  }, []);

  const languages = [
    { value: "english", label: "English" },
    { value: "hindi", label: "Hindi" },
    { value: "marathi", label: "Marathi" },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelect = (value) => {
    setSelectedLanguage(value);
    setIsOpen(false);
    setDoctorTag(value === "english" ? "Dr." : "डॉ.");
    setFormLang(value);
    setSelectedSpeciality({});
    setValue("speciality", null);
  };

  const openGooglePermission = () => {
    if (window?.android_bridge) {
      window?.android_bridge?.deviceLocationPermission(true);
    } else if (window?.webkit) {
      window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
        functionName: "deviceLocationPermission",
        data: true,
      });
    }
  };

  useEffect(() => {
    openGooglePermission();
  }, []);

  const languagesArray = {
    english: {
      mainHeader: "Let's start with",
      simMainHeader: "setting up your Chemist",
      fName: "First Name",
      fNamePlaceholder: "Enter First Name",
      lName: "Last Name",
      lNamePlaceholder: "Enter Last Name",
      speciality: "Speciality",
      specialityPlaceholder: "Select Speciality",
      email: "Email",
      emailPlaceholder: "Enter Email",
      clinicName: "Shop Name",
      doctorNumber: "Shop Mobile Number",
      doctorNoPlaceholder: "Enter Mobile Number",
      mciNumber: "License",
      mciNoPlaceholder: "Enter license No.",
      whatsappNumber: " WhatsApp Number",
      whatsappNoPlaceholder: "Enter WhatsApp Number",
      clinicNaPlaceholder: "Enter Shop Name",
      clinicNumber: "Shop Number",
      location: "Location",
      useMapLocation: "Use Map Location",
      clinicNoPlaceholder: "Enter Shop Number",
      address: "Address",
      addressPlaceHolder: "Enter Address",
      professionalDegree: "Professional Degree",
      profPlaceholder: "Enter Professional Degree",
      Timings: "Timings",
      doctorPhoto: "Doctor Photo",
      updateCard: "Update E-Card",
      updateFestiveCard: "Update Festive Card",
      designation: "Designation",
      designationPlaceholder: "Enter Your Designation",
    },
    hindi: {
      mainHeader: "चलिए शुरू करें",
      simMainHeader: "अपने क्लिनिक की स्थापना करें",
      fName: "पहला नाम",
      fNamePlaceholder: "पहला नाम दर्ज करें",
      lName: "अंतिम नाम",
      lNamePlaceholder: "अंतिम नाम दर्ज करें",
      speciality: "विशेषता",
      specialityPlaceholder: "विशेषता दर्ज करें",
      email: "ईमेल (वैकल्पिक)",
      emailPlaceholder: "ईमेल दर्ज करें",
      doctorNumber: "डॉक्टर नंबर",
      doctorNoPlaceholder: "डॉक्टर नंबर दर्ज करें",
      mciNumber: "डॉक्टर एमसीआई",
      mciNoPlaceholder: "एमसीआई दर्ज करें",
      whatsappNumber: "डॉक्टर व्हाट्सएप नंबर",
      whatsappNoPlaceholder: "व्हाट्सएप नंबर दर्ज करें",
      clinicName: "क्लिनिक का नाम",
      clinicNaPlaceholder: "क्लिनिक का नाम दर्ज करें",
      clinicNumber: "क्लिनिक नंबर",
      location: "स्थान",
      useMapLocation: "मानचित्र स्थान का उपयोग करें",
      clinicNoPlaceholder: "क्लिनिक नंबर दर्ज करें",
      address: "पता",
      addressPlaceHolder: "पता दर्ज करें",
      professionalDegree: "पेशेवर डिग्री",
      profPlaceholder: "पेशेवर डिग्री दर्ज करें",
      Timings: "समय",
      doctorPhoto: "डॉक्टर की फोटो",
      updateCard: "ई-कार्ड अपडेट करें",
      updateFestiveCard: "फेस्टिव कार्ड अपडेट करें",
      designation: "पद",
      designationPlaceholder: "अपना पद दर्ज करें", // Added missing key
    },
    marathi: {
      mainHeader: "चला सुरू करूया",
      simMainHeader: "तुमचा क्लिनिक सेट अप करा",
      fName: "पहिले नाव",
      fNamePlaceholder: "पहिले नाव प्रविष्ट करा",
      lName: "शेवटचे नाव",
      lNamePlaceholder: "शेवटचे नाव प्रविष्ट करा",
      speciality: "विशेषता",
      specialityPlaceholder: "विशेषता प्रविष्ट करा",
      email: "ईमेल (ऐच्छिक)",
      emailPlaceholder: "ईमेल प्रविष्ट करा",
      doctorNumber: "डॉक्टर क्रमांक",
      doctorNoPlaceholder: "डॉक्टर क्रमांक प्रविष्ट करा",
      mciNumber: "डॉक्टर एमसीआय",
      mciNoPlaceholder: "एमसीआय प्रविष्ट करा",
      whatsappNumber: "डॉक्टर व्हॉट्सअ‍ॅप नंबर",
      whatsappNoPlaceholder: "व्हॉट्सअ‍ॅप नंबर प्रविष्ट करा",
      clinicName: "क्लिनिकचे नाव",
      clinicNaPlaceholder: "क्लिनिकचे नाव प्रविष्ट करा",
      clinicNumber: "क्लिनिक क्रमांक",
      location: "स्थान",
      useMapLocation: "नकाशाचे स्थान वापरा",
      clinicNoPlaceholder: "क्लिनिक क्रमांक प्रविष्ट करा",
      address: "पत्ता",
      addressPlaceHolder: "पत्ता प्रविष्ट करा",
      professionalDegree: "व्यावसायिक पदवी",
      profPlaceholder: "व्यावसायिक पदवी प्रविष्ट करा",
      Timings: "वेळापत्रक",
      doctorPhoto: "डॉक्टरचा फोटो",
      updateCard: "ई-कार्ड अद्यतनित करा",
      updateFestiveCard: "सणाचा कार्ड अद्यतनित करा",
      designation: "पद",
      designationPlaceholder: "तुमचा पद प्रविष्ट करा", // Added missing key
    },
  };

  const langClickOutside = (event) => {
    if (langRef.current && !langRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", langClickOutside);
    return () => {
      document.removeEventListener("mousedown", langClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen">
      <Header isBack={true} showSearch={true} />
      <div className="mt-16 p-4">
        <div className="flex justify-between items-center">
          <div>
            <h2
              className={`text-xl ${
                selectedLanguage === "english" ? "font-semibold" : ""
              } mb-2`}
            >
              {languagesArray?.[selectedLanguage]?.mainHeader}
            </h2>
            <h2
              className={`text-xl ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.simMainHeader}
            </h2>
          </div>

          <div>
            <div className="relative inline-block text-left">
              <div>
                <label
                  htmlFor="language"
                  className="block text-[10px] font-medium text-gray-700 mb-1"
                >
                  Choose a language:
                </label>
                <div>
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className="w-full flex justify-between items-center bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    {
                      languages.find((lang) => lang.value === selectedLanguage)
                        ?.label
                    }
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 10l5 5 5-5H7z"
                      />
                    </svg>
                  </button>
                  {isOpen && (
                    <div
                      ref={langRef}
                      className="absolute mt-1 w-full bg-white rounded-md shadow-lg z-10"
                    >
                      {languages.map((lang) => (
                        <button
                          key={lang.value}
                          onClick={() => handleSelect(lang.value)}
                          className={`block w-full text-left px-4 py-2 text-[10px] ${
                            selectedLanguage === lang.value
                              ? "bg-blue-500 text-white"
                              : "text-gray-900 hover:bg-blue-100"
                          }`}
                        >
                          {lang.label}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="">
          {/* Doctor F-Name */}
          <div className="mb-3">
            <label
              htmlFor="firstName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.fName}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              id="firstName"
              className={`${
                errors.firstName ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.fNamePlaceholder}
              {...register("firstName", {
                required: "* Last Name is required",
                pattern: {
                  value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                  message: "* Enter valid Last Name (only characters)",
                },
              })}
            />
            {errors.firstName && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.firstName.message}
              </p>
            )}
          </div>
          {/* Doctor L-Name */}
          <div className="mb-3">
            <label
              htmlFor="lastName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.lName}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              id="lastName"
              className={`${
                errors.lastName ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.lNamePlaceholder}
              {...register("lastName", {
                required: "* Last Name is required",
                pattern: {
                  value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                  message: "* Enter valid Last Name (only characters)",
                },
              })}
            />
            {errors.lastName && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.lastName.message}
              </p>
            )}
          </div>
          {/* Doctor Email */}
          <div className="mb-3">
            <label
              htmlFor="email"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.email}
            </label>
            <input
              type="email"
              id="email"
              className={`${
                errors.email ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.emailPlaceholder}
              {...register("email", {
                required: false,
              })}
            />
            {errors.email && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.email.message}
              </p>
            )}
          </div>
          {/* Doctor mci Number*/}
          <div className="mb-3">
            <label
              htmlFor="mciNumber"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.mciNumber}
            </label>
            <input
              type="text"
              id="mciNumber"
              className={`${
                errors.mciNumber ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.mciNoPlaceholder}
              {...register("mciNumber", {
                required: false,
              })}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="designation"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.designation}
            </label>
            <input
              type="text"
              id="designation"
              className={`${
                errors.designation ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.designationPlaceholder
              }
              {...register("designation", {
                required: false,
                pattern: {
                  value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                  message: "* Enter valid designation  (only characters)",
                },
              })}
            />
            {errors.designation && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.designation.message}
              </p>
            )}
          </div>
          {/* Doctor Clinic Name */}
          <div className="mb-3">
            <label
              htmlFor="clinicName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.clinicName}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              id="clinicName"
              className={`${
                errors.clinicName ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.clinicNaPlaceholder
              }
              {...register("clinicName", {
                required: "* Clinic Name is required",
              })}
            />
            {errors.clinicName && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.clinicName.message}
              </p>
            )}
          </div>
          {/* Doctor Clinic Number */}
          <div className="mb-3">
            <label
              htmlFor="clinicNumber"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.clinicNumber}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="number"
              id="clinicNumber"
              className={`${
                errors.clinicNumber ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
              }
              {...register("clinicNumber", {
                required: "* Clinic Number is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "* Enter a valid number",
                },
                minLength: {
                  value: 10,
                  message: " *Please enter correct numbers",
                },
                maxLength: {
                  value: 10,
                  message: " *Please enter Maximum 10 numbers",
                },
              })}
            />
            {errors.clinicNumber && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.clinicNumber.message}
              </p>
            )}
          </div>
          {/* Doctor Number */}
          <div className="mb-3">
            <label
              htmlFor="doctorNumber"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.doctorNumber}
            </label>
            <input
              type="number"
              id="doctorNumber"
              className={`${
                errors.doctorNumber ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
              }
              {...register("doctorNumber", {
                required: false,
              })}
            />
            {errors.doctorNumber && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.doctorNumber.message}
              </p>
            )}
          </div>
          {/* Doctor WhatsApp Number */}
          <div className="mb-3">
            <label
              htmlFor="whatsappNumber"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.whatsappNumber}
            </label>
            <input
              type="number"
              id="whatsappNumber"
              className={`${
                errors.whatsappNumber ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
              }
              {...register("whatsappNumber", {
                required: false,
              })}
            />
            {errors.whatsappNumber && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.whatsappNumber.message}
              </p>
            )}
          </div>
          {/* Location */}
          <label
            htmlFor="location"
            className={`text-xs text-gray-400 ${
              selectedLanguage === "english" ? "font-semibold" : ""
            }`}
          >
            {languagesArray?.[selectedLanguage]?.location}
          </label>
          <button
            type="button"
            id="location"
            className={`flex p-2 bg-blue-500 text-white shadow-gray-500 shadow-md ${
              selectedLanguage === "english" ? "font-semibold" : ""
            } rounded mt-2 text-sm`}
            onClick={() => {
              handleShowMap();
              openGooglePermission();
            }}
          >
            {languagesArray?.[selectedLanguage]?.useMapLocation}{" "}
            <ImLocation2 className="mt-1 ml-1" />
          </button>
          {showMap && (
            <SimpleMap
              setGeoLocation={setGeoLocation}
              geoLocation={geoLocation}
              setShowMap={setShowMap}
              setValue={setValue}
              onCloseMap={handleCloseMap}
            />
          )}
          {Object.keys(geoLocation).length > 0 && (
            <Map geoLocation={geoLocation} />
          )}
          {/* Doctor Address */}
          <div className="my-3">
            <label
              htmlFor="address"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.address}
              <span className="text-red-500"> *</span>
            </label>

            <textarea
              // onClick={handleScreenScroll}
              id="address"
              rows={4}
              className={`${
                errors.address ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.addressPlaceHolder
              }
              {...register("address", {
                required: "* Address is required",
              })}
            />
            {errors.address && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.address.message}
              </p>
            )}
          </div>
          {/* Timings */}
          <div className="mb-3">
            <label
              htmlFor="timings"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.Timings}
            </label>
            <button
              onClick={() => setOpenTiming(true)}
              className="flex p-2 bg-blue-500 text-white shadow-gray-500 shadow-md font-semibold rounded mt-2 text-sm items-center gap-1"
              type="button"
            >
              Add Timing
              <IoMdTime className="text-lg" />
            </button>
            {openTiming && (
              <Timing
                days={days}
                setPopup={setOpenTiming}
                setCopyToPopup={setCopyToPopup}
                copyToPopup={copyToPopup}
              />
            )}
          </div>
          {/* Doctor Photo */}
          <div className="mb-3">
            <label
              htmlFor="photo"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              Shop Photo
            </label>
            <input
              type="file"
              id="photo"
              className="focus-within:outline-none text-sm p-2 border border-gray-300 rounded-lg w-full mt-1.5"
              {...register("photo", {
                required: false,
              })}
              onChange={handleImageChange}
            />
            {imagePreview && (
              <div className="mt-3">
                <img
                  src={imagePreview}
                  alt="Selected"
                  className="h-48 w-48 object-contain rounded mt-1.5"
                />
              </div>
            )}
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-3 py-2 rounded-lg mt-4"
          >
            Update your Card
          </button>
        </form>
        {copyToPopup && (
          <>
            <button
              className="fixed bottom-[16px] left-3 z-[999] bg-red-600 text-white rounded-md m-2 w-fit px-3 py-1"
              onClick={() => setCopyToPopup(false)}
            >
              close
            </button>
            <CopyPopup setPopup={setCopyToPopup} copyToPopup={copyToPopup} />
          </>
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default ChemistForm;

const CopyPopup = ({ setPopup, copyToPopup }) => {
  const [selected, setSelected] = useState([]);
  const [nodes, setNodes] = useState([]);
  const timeSlot = timeSlots((state) => state.timeSlot);
  const setTimeSlot = timeSlots((state) => state.setTimeSlot);

  const days = [
    {
      value: "Monday",
      label: "Monday",
    },
    {
      value: "Tuesday",
      label: "Tuesday",
    },
    {
      value: "Wednesday",
      label: "Wednesday",
    },
    {
      value: "Thursday",
      label: "Thursday",
    },
    {
      value: "Friday",
      label: "Friday",
    },
    {
      value: "Saturday",
      label: "Saturday",
    },
    {
      value: "Sunday",
      label: "Sunday",
    },
  ];

  useEffect(() => {
    setNodes(days.filter((d) => d.value !== copyToPopup.title));
  }, [copyToPopup]);

  const copyTime = () => {
    let selectedTime = timeSlot[copyToPopup.title][copyToPopup.clinicType];
    let temp = timeSlot;
    selected.forEach((d) => {
      temp = {
        ...temp,
        [d]: {
          ...temp[d],
          [copyToPopup.clinicType]: selectedTime,
        },
      };
    });
    setTimeSlot(temp);
    setPopup(false);
  };

  return (
    <div
      onClick={() => setPopup(false)}
      className="fixed top-0 left-0 w-screen z-[99] bg-white"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        // initial={{ y: "100%" }}
        // animate={{ y: 0 }}
        className="fixed p-4 bottom-0 w-screen z-20 bg-white rounded-t-xl overflow-hidden"
      >
        <p className="text-lg text-gray-900 font-semibold">
          Select days to copy time slots from {copyToPopup.title}
        </p>

        <div>
          <CheckboxTree
            nodes={nodes}
            checked={selected}
            onCheck={(checked) => {
              setSelected(checked);
            }}
            className="NodeLeaf"
          />
          <div onClick={selected.length > 0 ? copyTime : void 0}>
            <buttom>
              <p className="text-sm text-white ml-auto bg-blue-500 rounded-md m-2 w-fit px-3 py-2 font-medium">
                Copy
              </p>
            </buttom>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

function Map({ geoLocation }) {
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${geoLocation?.lat},${geoLocation?.lng}&zoom=19&size=600x300&maptype=roadmap&markers=color:red%7C${geoLocation?.lat},${geoLocation?.lng}&key=${geoLocationKey}`;
  return (
    <div className="mt-4 border border-gray-300 rounded-md">
      <img height={200} src={mapUrl} alt="Map" />
    </div>
  );
}
