import { create } from "zustand";
import { persist } from "zustand/middleware";

export const onBoardStoreData = create(
  persist(
    (set) => ({
      doctorData: {},
      setDoctorData: (data) => set(() => ({ doctorData: data })),
      clearDoctorData: () => set(() => ({ doctorData: {} })),
      doctorImg: "",
      setDoctorImg: (data) => set(() => ({ doctorImg: data })),
      doctorTag: "Dr.", // Default value for doctorTag
      setDoctorTag: (tag) => {
        set({ doctorTag: tag });
      },
      formLang: "english",
      setFormLang: (data) => set(() => ({ formLang: data })),
      selectedSpeciality: null,
      setSelectedSpeciality: (data) =>
        set(() => ({ selectedSpeciality: data })),
      specialityLangArray: null,
      setSpecialityLangArray: (data) =>
        set(() => ({ specialityLangArray: data })),
      festiveCardType: "",
      setFestiveCardType: (data) => set(() => ({ festiveCardType: data })),
      brandServiceDrop: null,
      setBrandServiceDrop: (data) => set(() => ({ brandServiceDrop: data })),
      brandSpecData: null,
      setBrandSpecData: (data) => set(() => ({ brandSpecData: data })),
      brandSpeciality: "",
      setBrandSpeciality: (data) => set(() => ({ brandSpeciality: data })),
      repDetails: "",
      setRepDetails: (data) => set(() => ({ repDetails: data })),
      journalDetail: {},
      setJournalDetail: (data) => set(() => ({ journalDetail: data })),
      cardPreviewHtml: "",
      setCardPreviewHtml: (data) => set(() => ({ cardPreviewHtml: data })),
      cardDownloadHtml: "",
      setCardDownloadHtml: (data) => set(() => ({ cardDownloadHtml: data })),
      drImgStatus: false,
      setDrImgStatus: (data) => set(() => ({ drImgStatus: data })),
      spouseStatus: false,
      setSpouseStatus: (data) => set(() => ({ spouseStatus: data })),
    }),
    {
      name: "doctor_card_data",
      getStorage: () => localStorage,
    }
  )
);
